var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isValid
        ? _c("form-summary", {
            staticClass: "form-errors alert alert-danger",
            attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
          })
        : _vm._e(),
      _c("CForm", [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column flex-md-row" },
              [
                _c("CTextarea", {
                  staticClass: "w-100",
                  attrs: {
                    rows: "2",
                    label: "Internal Note:",
                    placeholder: "Internal Note",
                    lazy: false,
                    value: _vm.$v.form.note_internal.$model,
                  },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(
                        _vm.$v.form.note_internal,
                        "$model",
                        $event
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
              _c("div", { staticClass: "form-group w-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-gap-1 align-items-center pb-2 mb-2 border-bottom",
                  },
                  [
                    _c("CIcon", { attrs: { name: "cil-list-rich" } }),
                    _c("label", { staticClass: "mb-0" }, [
                      _vm._v("Checklist:"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "ul",
                  { staticClass: "checklist" },
                  _vm._l(_vm.checkList, function (item) {
                    return _c("li", [
                      _c("label", { staticClass: "for-checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.checked_list,
                              expression: "form.checked_list",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: item,
                            checked: Array.isArray(_vm.form.checked_list)
                              ? _vm._i(_vm.form.checked_list, item) > -1
                              : _vm.form.checked_list,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.checked_list,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "checked_list",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "checked_list",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "checked_list", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(item) + " "),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
              _c("div", { staticClass: "form-group w-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-gap-1 align-items-center pb-2 mb-2 border-bottom",
                  },
                  [
                    _c("CIcon", { attrs: { name: "cil-pen-nib" } }),
                    _c("label", { staticClass: "mb-0" }, [_vm._v("Contract:")]),
                  ],
                  1
                ),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-2 d-flex flex-column flex-md-row" },
                    [
                      _c("div", { staticClass: "form-group w-100" }, [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Contract Language:"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "contract-lang-group" },
                          _vm._l(_vm.allLanguages, function (lang) {
                            return _c(
                              "div",
                              {
                                key: lang.value,
                                staticClass: "form-check form-check-inline",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formContract.contract_lang,
                                      expression: "formContract.contract_lang",
                                    },
                                  ],
                                  staticClass: "form-check-input d-none",
                                  attrs: {
                                    type: "radio",
                                    name: "lang",
                                    id: `lang_${lang.value}`,
                                  },
                                  domProps: {
                                    value: lang.value,
                                    checked: _vm._q(
                                      _vm.formContract.contract_lang,
                                      lang.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.formContract,
                                        "contract_lang",
                                        lang.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: `lang_${lang.value}` },
                                  },
                                  [
                                    lang.value == "en"
                                      ? _c("CIcon", {
                                          staticClass: "flag mr-1",
                                          attrs: {
                                            width: 21,
                                            height: 16,
                                            content:
                                              _vm.$options.flagSet["cifGb"],
                                          },
                                        })
                                      : _c("CIcon", {
                                          staticClass: "flag mr-1",
                                          attrs: {
                                            height: 14,
                                            content:
                                              _vm.$options.flagSet[
                                                "cif" +
                                                  lang.value
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  lang.value.slice(1)
                                              ],
                                          },
                                        }),
                                    _vm._v(" " + _vm._s(lang.label) + " "),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-2 d-flex flex-column flex-md-row" },
                    [
                      _c("CInput", {
                        staticClass: "w-100 mr-2",
                        attrs: {
                          label: "Name:",
                          placeholder: "Name",
                          lazy: false,
                          value: _vm.formContract.name,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.formContract, "name", $event)
                          },
                        },
                      }),
                      _c("CInput", {
                        staticClass: "w-100",
                        attrs: {
                          label: "Place:",
                          placeholder: "Place",
                          lazy: false,
                          value: _vm.formContract.place,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.formContract, "place", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group w-100 mb-0" },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Signature:"),
                        ]),
                        _c("VueSignaturePad", {
                          ref: "signaturePad",
                          attrs: {
                            id: "signature",
                            width: "100%",
                            height: "200px",
                            options: {
                              penColor: "#000",
                              onBegin: () => {
                                _vm.$refs.signaturePad.resizeCanvas()
                              },
                            },
                          },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "small",
                            attrs: { role: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.signaturePad.undoSignature()
                              },
                            },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-action-undo" } }),
                            _vm._v(" Undo the Signature "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "w-100" }),
                  ]),
                ]),
              ]),
            ]),
            _c("RestaurantContract", {
              ref: "contract",
              attrs: {
                form: _vm.form,
                formContract: _vm.formContract,
                sign: _vm.contractSign,
              },
              on: { generated: _vm.submit },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }